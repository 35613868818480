<template>
    <div class="col-sm-12 mt-4">
        <ValidationObserver v-slot="{ passes }">
            <form class="form-auth-small" @submit.prevent="passes(sendbyCard)" id="registration" ref="registration">
                <ValidationProvider name="full-name" rules="required" v-slot="{ errors}">
                    <div class="form-group" :class="{'errors': errors.length && !paybyCard.fullName,'success':paybyCard.fullName}">
                        <span class="float-label">
                            <input type="text" id="fullName" v-model="paybyCard.fullName" class="form-control" placeholder="Full Name as Displayed on Card*">
                            <label for="fullName" class="control-label">Full Name as Displayed on Card*</label>
                            <div class="line"></div>
                            <i class="fa fa-times" v-if="errors.length && !paybyCard.fullName"></i>
                            <i class="fa fa-check" v-if="paybyCard.fullName"></i>
                        </span>
                    </div>
                </ValidationProvider>
                <ValidationProvider name="Card Number" rules="required" v-slot="{ errors}">
                    <div class="form-group" :class="{'errors': errors.length && !paybyCard.cardNumber,'success':paybyCard.cardNumber}">
                        <span class="float-label">
                            <input type="text" id="cardNumber" v-model="paybyCard.cardNumber" class="form-control" placeholder="Enter Card Number*" @keypress="enterOnlyNumber($event)">
                            <label for="cardNumber" class="control-label">Enter Card Number*</label>
                            <div class="line"></div>
                            <i class="fa fa-times" v-if="errors.length && !paybyCard.cardNumber"></i>
                            <i class="fa fa-check" v-if="paybyCard.cardNumber"></i>
                            <i class="fa fa-credit-card"></i>
                        </span>
                    </div>
                </ValidationProvider>
                <div class="row">
                    <div class="col-md-6">
                        <ValidationProvider name="expiryDate" rules="required" v-slot="{ errors }">
                            <div class="form-group datepicker" :class="{'errors': errors[0],'success':paybyCard.expiryDate && !errors[0]}">
                                <span class="float-label">
                                    <datepicker class="form-control" :bootstrap-styling="false" v-model="paybyCard.expiryDate" :typeable="true"></datepicker>
                                    <label for="cardNumber" class="control-label">Expiry Date*</label>
                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                    <i class="fa fa-check" v-if="paybyCard.expiryDate && !errors[0]"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                        <ValidationProvider name="cvv" rules="required" v-slot="{ errors}">
                            <div class="form-group" :class="{'errors': errors.length && !paybyCard.cvv,'success':paybyCard.cvv}">
                                <span class="float-label">
                                    <input type="text" v-model="paybyCard.cvv" :maxlength="3" class="form-control" id="cvv" placeholder="0.00"  @keypress="enterOnlyNumber($event)">
                                    <label for="cvv" class="control-label">CVV*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !paybyCard.cvv"></i>
                                    <i class="fa fa-check" v-if="paybyCard.cvv"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center m-b-20">
                    <p class="mb-0">Save this account for future use.</p>
                    <div class="custom-switch success-error-bg">
                        <input type="checkbox" class="custom-switch-input" id="tamount" v-model="paybyCard.futureUseIs">
                        <label class="custom-switch-label" for="tamount">
                            <span class="custom-switch-text1">Yes</span>
                            <span class="custom-switch-text2">No</span>
                        </label>
                    </div>
                </div>
                <div class="phone-with-country d-flex justify-content-between align-items-center disabled">
                    <div class="select-country">
                        <div class="form-group form-select single-multiselect">
                            <span class="float-label">
                                <label for="amount">Amount</label>
                                <multiselect
                                    placeholder=""
                                    v-model="paybyCard.currency"   
                                    :show-labels="false" 
                                    :options="currencyOptions"
                                    :searchable="false"
                                    label="name" 
                                    track-by="name">
                                    <!-- disabled -->
                                    <template slot="singleLabel" slot-scope="props">
                                        <span class="mr-2" :class="props.option.flagClass"></span>
                                        <span class="option-title">
                                            <span>{{ props.option.name }}</span>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">
                                        <div class="option-title">
                                            <span class="mr-2" :class="props.option.flagClass"></span>
                                            <span>{{ props.option.name }}</span>
                                        </div>
                                    </template>
                                </multiselect> 
                            </span>
                        </div>
                    </div>
                    <div class="add-number">
                        <div class="form-group">
                            <span class="float-label">
                                <input type="text" v-model="paybyCard.amount" class="form-control" id="amount" placeholder="0.00" @keypress="enterOnlyNumber($event)">
                                <div class="line"></div>
                            </span>
                        </div>
                    </div>
                </div>
                <button type="submit" class="btn btn-gradient btn-lg btn-mw btn-block">Pay by Card</button>
            </form> 
        </ValidationObserver>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider } = require("vee-validate")
export default {
    name:'PaybyCard',
    components: {
        ValidationObserver,
        ValidationProvider,
        Datepicker: () => import('vuejs-datepicker'),
        Multiselect: () => import('vue-multiselect'),
    },data(){    
        return{
            paybyCard:{
                fullName:'',
                cardNumber:'',
                expiryDate:'',
                cvv:'',
                futureUseIs:true,
                currency:{ name: 'USD', flagClass: "flag-icon flag-icon-us fi-md fi-round"},
                amount:500   
            },
        
            currencyOptions:[
                { name: 'GBP', flagClass: "flag-icon flag-icon-gb fi-md fi-round"},
                { name: 'GHS', flagClass: "flag-icon flag-icon-gh fi-md fi-round"},
                { name: 'USD', flagClass: "flag-icon flag-icon-us fi-md fi-round"},
                { name: 'RUB', flagClass:"flag-icon flag-icon-ru fi-md fi-round"},
            ],
        }
    },methods:{
        sendbyCard(){
            this.$router.push({ path: '/payment/payment-transfer' })
        },

        enterOnlyNumber(event) {
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if (keyCode < 48 || keyCode > 57) { 
                event.preventDefault();
            }
        }
        
    }
}
</script>
